import BooleanInput from "../../inputs/BooleanInput";
import TextInput from "../../inputs/TextInput";
import SelectInput from "../../inputs/ChoicesInput";
import { FormNodeContentRowProps } from "./types";
import PopoverPanel from "../../Popover";
import colorChangeState from "../../../utils/node/colorChangeState";

const NodeContentRow = ({
  nodeId,
  rowIndex,
  rowValue: value,
  register,
  enableDrag,
  handleRemoveColumn,
}: FormNodeContentRowProps) => {
  return (
    <div
      key={`${nodeId}-value-${value.id}`}
      className={`flex flex-row gap-2 items-center group text-nepal-950 font-normal`}
    >
      <div
        className={`w-full flex flex-row gap-2 ${
          colorChangeState(value.changeState).textColor
        } ${value.changeState ? "font-medium" : ""}`}
      >
        {enableDrag ? (
          <>
            <div className="block w-full rounded-md border-0 py-1.5 px-1 ring-inset ring-1 ring-gray-500 text-sm border-nepal-950 bg-nepal-200">
              <span>{value.name}</span>
            </div>

            <div className="block w-full rounded-md border-0 py-1.5 px-1 ring-inset ring-1 ring-gray-500 text-sm border-nepal-950 bg-nepal-200">
              <span>{value.type}</span>
            </div>
          </>
        ) : (
          <>
            <TextInput
              name={`data.values.${rowIndex}.name`}
              label=""
              defaultValue={value.name}
              register={register}
            />

            <TextInput
              name={`data.values.${rowIndex}.type`}
              label=""
              defaultValue={value.type}
              register={register}
            />
          </>
        )}
      </div>

      <div>
        <PopoverPanel
          id={`popover-${value.id}`}
          trigger={
            <svg
              className="w-4 h-4 fill-nepal-800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 128 512"
            >
              <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
            </svg>
          }
          openTrigger={
            <svg
              className={`h-6 fill-nepal-800 rotate-90`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
            </svg>
          }
          position="right"
        >
          <div className="w-80 py-4 flex flex-col justify-start items-start gap-2">
            {/* Options */}
            <div className="px-4 flex flex-col gap-4 w-full">
              <h3 className="font-medium mb-1">Options :</h3>

              <SelectInput
                name={`data.values.${rowIndex}.index_type`}
                label="Index Type"
                options={[
                  {
                    value: "",
                    label: "Aucun",
                    iconSVG: (
                      <svg
                        className="w-4 h-4 fill-nepal-800"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      ></svg>
                    ),
                  },
                  {
                    value: "primary_key",
                    label: "Primary key",
                    iconSVG: (
                      <svg
                        className="w-4 h-4 fill-nepal-800"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17l0 80c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-40 40 0c13.3 0 24-10.7 24-24l0-40 40 0c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
                      </svg>
                    ),
                  },
                  {
                    value: "unique_key",
                    label: "Unique key",
                    iconSVG: (
                      <svg
                        className="w-4 h-4 fill-nepal-800"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M224 0c17.7 0 32 14.3 32 32l0 30.1 15-15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-49 49 0 70.3 61.4-35.8 17.7-66.1c3.4-12.8 16.6-20.4 29.4-17s20.4 16.6 17 29.4l-5.2 19.3 23.6-13.8c15.3-8.9 34.9-3.7 43.8 11.5s3.8 34.9-11.5 43.8l-25.3 14.8 21.7 5.8c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17l-67.7-18.1L287.5 256l60.9 35.5 67.7-18.1c12.8-3.4 26 4.2 29.4 17s-4.2 26-17 29.4l-21.7 5.8 25.3 14.8c15.3 8.9 20.4 28.5 11.5 43.8s-28.5 20.4-43.8 11.5l-23.6-13.8 5.2 19.3c3.4 12.8-4.2 26-17 29.4s-26-4.2-29.4-17l-17.7-66.1L256 311.7l0 70.3 49 49c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15 0 30.1c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-30.1-15 15c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l49-49 0-70.3-61.4 35.8-17.7 66.1c-3.4 12.8-16.6 20.4-29.4 17s-20.4-16.6-17-29.4l5.2-19.3L48.1 395.6c-15.3 8.9-34.9 3.7-43.8-11.5s-3.7-34.9 11.5-43.8l25.3-14.8-21.7-5.8c-12.8-3.4-20.4-16.6-17-29.4s16.6-20.4 29.4-17l67.7 18.1L160.5 256 99.6 220.5 31.9 238.6c-12.8 3.4-26-4.2-29.4-17s4.2-26 17-29.4l21.7-5.8L15.9 171.6C.6 162.7-4.5 143.1 4.4 127.9s28.5-20.4 43.8-11.5l23.6 13.8-5.2-19.3c-3.4-12.8 4.2-26 17-29.4s26 4.2 29.4 17l17.7 66.1L192 200.3l0-70.3L143 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l15 15L192 32c0-17.7 14.3-32 32-32z" />
                      </svg>
                    ),
                  },
                  {
                    value: "index",
                    label: "Index",
                    iconSVG: (
                      <svg
                        className="w-4 h-4 fill-nepal-800"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 384 512"
                      >
                        <path d="M32 32C32 14.3 46.3 0 64 0L320 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-29.5 0 11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3L32 352c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64 64 64C46.3 64 32 49.7 32 32zM160 384l64 0 0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96z" />
                      </svg>
                    ),
                  },
                ]}
                register={register}
                defaultValue={value.index_type ?? ""}
              />

              {value.index_key && (
                <TextInput
                  name={`data.values.${rowIndex}.index_key`}
                  label="Index Key"
                  defaultValue={value.index_key}
                  register={register}
                />
              )}

              <SelectInput
                name={`data.values.${rowIndex}.changeState`}
                label="Modification"
                options={[
                  {
                    value: "",
                    label: "Aucun",
                  },
                  {
                    value: "delete",
                    label: "Suppression",
                  },
                  {
                    value: "update",
                    label: "Modification",
                  },
                  {
                    value: "new",
                    label: "Ajout",
                  },
                ]}
                register={register}
                defaultValue={value.changeState ?? ""}
              />

              <div className="flex flex-row gap-2">
                <h5 className="font-normal">Nullable :</h5>
                <BooleanInput
                  name={`data.values.${rowIndex}.nullable`}
                  label=""
                  register={register}
                  defaultValue={value.nullable}
                />
              </div>
            </div>

            {/* Separator */}
            <div className="bg-nepal-900 h-0.5 w-full opacity-15" />

            {/* Actions */}
            <div className="px-4">
              <h3 className="font-medium mb-1">Actions :</h3>
              <button
                className="flex flex-row gap-2 justify-center items-center rounded-md border-0 py-1.5 px-1 shadow-sm ring-inset ring-1 ring-red-700 text-sm border-nepal-950 bg-red-700 bg-opacity-15 hover:bg-opacity-30 duration-300"
                type="button"
                onClick={() => handleRemoveColumn(value.id)}
              >
                <svg
                  className="w-4 h-4 fill-red-700 "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                </svg>
                Supprime la colonne
              </button>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </div>
  );
};

export default NodeContentRow;
