import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
} from "react";

interface PopoverContextProps {
  openPopover: (id: string) => void;
  closePopover: (id: string) => void;
  isOpen: (id: string) => boolean;
}

const PopoverContext = createContext<PopoverContextProps | undefined>(
  undefined
);

interface PopoverProviderProps {
  children: ReactNode;
}

export const PopoverProvider: React.FC<PopoverProviderProps> = ({
  children,
}) => {
  const [openPopovers, setOpenPopovers] = useState<string[]>([]);

  const openPopover = (id: string) => {
    setOpenPopovers([id]); // Set only the new id, effectively closing others
  };

  const closePopover = (id: string) => {
    setOpenPopovers((prev) => prev.filter((popoverId) => popoverId !== id));
  };

  const isOpen = (id: string) => openPopovers.includes(id);

  const contextValue = useMemo(
    () => ({ openPopover, closePopover, isOpen }),
    [openPopovers]
  );

  return (
    <PopoverContext.Provider value={contextValue}>
      {children}
    </PopoverContext.Provider>
  );
};

export const usePopover = () => {
  const context = useContext(PopoverContext);
  if (!context) {
    throw new Error("usePopover must be used within an PopoverProvider");
  }
  return context;
};
