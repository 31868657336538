import { create } from "zustand";
import { NodeDefaultInterface } from "../interfaces/NodeDefaultInterface";
import { EdgeDefaultInterface } from "../interfaces/EdgeDefaultInterface";
import { Viewport } from "@xyflow/react";
import getMaxSizeLocalstorage from "../utils/getMaxSizeLocalstorage";
import getCurrentSizeLocalstorage from "../utils/getCurrentSizeLocalstorage";

export interface DiagramData {
  nodes: Array<NodeDefaultInterface>;
  edges: Array<EdgeDefaultInterface>;
  viewport: Viewport | undefined;
}

interface DiagramStoreState {
  nodes: Array<NodeDefaultInterface>;
  edges: Array<EdgeDefaultInterface>;
  viewport: Viewport | undefined;
  initialNodes: (nodes: Array<NodeDefaultInterface>) => void;
  addNode: () => void;
  updateNode: (nodeId: string, newNode: NodeDefaultInterface) => void;
  removeNode: (nodeId: string) => void;
  addColumnNode: (nodeId: string) => void;
  removeColumnNode: (nodeId: string, columnId: string) => void;
  initialEdges: (edges: Array<EdgeDefaultInterface>) => void;
  getEdge: (id: string) => EdgeDefaultInterface | undefined;
  addEdge: (newEdge: EdgeDefaultInterface) => void;
  updateEdge: (edgeId: string, newEdge: EdgeDefaultInterface) => void;
  removeEdge: (edgeId: string) => void;
  updateViewport: (viewport: Viewport) => void;
}
const getInitialState = (): DiagramData => {
  const savedState = localStorage.getItem("diagramState");
  if (savedState) {
    let maxSize = getMaxSizeLocalstorage();
    let currentSize = getCurrentSizeLocalstorage();
    console.log(`Taille max en octets : ${maxSize} Ko`);
    console.log(`Taille en Ko : ${currentSize} Ko`);
    console.log(
      `Percentage de taille max : ${((currentSize / maxSize) * 100).toFixed(
        2
      )}%`
    );

    let t = 0;
    for (let x in localStorage) {
      t += (x.length + localStorage[x].length) * 2;
    }
    console.log(t / 1024 + " KB");

    let jsonState = JSON.parse(savedState) as DiagramData;
    if (jsonState) {
      jsonState.edges.forEach((edge: EdgeDefaultInterface) => {
        edge.type = "table";
      });

      return jsonState;
    }
  }

  return {
    nodes: [],
    edges: [],
    viewport: {
      x: 0,
      y: 0,
      zoom: 1,
    },
  };
};

const saveState = (state: DiagramStoreState) => {
  localStorage.setItem(
    "diagramState",
    JSON.stringify({
      nodes: state.nodes,
      edges: state.edges,
      viewport: state.viewport,
    })
  );
};

const useDiagramStore = create<DiagramStoreState>((set, get) => ({
  ...getInitialState(),
  initialNodes: (nodes: Array<NodeDefaultInterface>) =>
    set((state) => ({
      ...state,
      nodes: nodes,
    })),
  addNode: () =>
    set((state) => {
      const newNode: NodeDefaultInterface = {
        id: Math.random().toString(36).substring(2, 15),
        type: "table",
        data: {
          title: `table_${state.nodes.length}`,
          color: "#ffffff",
          values: [],
        },
        position: {
          x: 0,
          y: 0,
        },
      };

      const newState = {
        ...state,
        nodes: [...state.nodes, newNode],
      };

      saveState(newState);
      return newState;
    }),
  updateNode: (nodeId: string, newNode: NodeDefaultInterface) =>
    set((state) => {
      const newState = {
        ...state,
        nodes: state.nodes.map((node) => (node.id === nodeId ? newNode : node)),
      };

      saveState(newState);
      return newState;
    }),
  removeNode: (nodeId: string) =>
    set((state) => {
      const newState = {
        ...state,
        nodes: state.nodes.filter((node) => node.id !== nodeId),
        edges: state.edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        ),
      };

      saveState(newState);
      return newState;
    }),
  addColumnNode: (nodeId: string) =>
    set((state) => {
      const newState: DiagramStoreState = {
        ...state,
        nodes: state.nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                values: [
                  ...node.data.values,
                  {
                    id: Math.random().toString(36).substring(2, 15),
                    name: `champ_${node.data.values.length}`,
                    type: "int",
                    nullable: false,
                    index_type: null,
                    index_key: null,
                    changeState: null,
                  },
                ],
              },
            };
          }
          return node;
        }),
      };

      saveState(newState);
      return newState;
    }),
  removeColumnNode: (nodeId: string, columnId: string) =>
    set((state) => {
      const newState = {
        ...state,
        nodes: state.nodes.map((node) => {
          if (node.id === nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                values: node.data.values.filter((value) => {
                  console.log(value);

                  if (!value.id) return false;

                  return value.id !== columnId;
                }),
              },
            };
          }
          return node;
        }),
        edges: state.edges.filter((edge) => {
          const sourceSplit = edge.sourceHandle?.split("-");
          const targetSplit = edge.targetHandle?.split("-");

          if (!sourceSplit || !targetSplit) return true;

          const sourceNodeId = sourceSplit[3];
          const targetNodeId = targetSplit[3];

          if (sourceNodeId === columnId || targetNodeId === columnId) {
            return false;
          }

          return true;
        }),
      };

      saveState(newState);
      return newState;
    }),
  initialEdges: (edges: Array<EdgeDefaultInterface>) =>
    set((state) => ({
      ...state,
      edges: edges,
    })),
  getEdge: (id: string) => get().edges.find((edge) => edge.id === id),
  addEdge: (edge: EdgeDefaultInterface) =>
    set((state) => {
      const newEdge: EdgeDefaultInterface = {
        id: Math.random().toString(36).substring(2, 15),
        type: "table",
        source: edge.source,
        target: edge.target,
        sourceHandle: edge.sourceHandle,
        targetHandle: edge.targetHandle,
      };

      const newState = {
        ...state,
        edges: [...state.edges, newEdge],
      };

      saveState(newState);
      return newState;
    }),
  updateEdge: (edgeId: string, newEdge: EdgeDefaultInterface) =>
    set((state) => {
      const newState = {
        ...state,
        edges: state.edges.map((edge) => (edge.id === edgeId ? newEdge : edge)),
      };

      saveState(newState);
      return newState;
    }),
  removeEdge: (edgeId: string) =>
    set((state) => {
      const newState = {
        ...state,
        edges: state.edges.filter((edge) => edge.id !== edgeId),
      };

      saveState(newState);
      return newState;
    }),
  updateViewport: (viewport: Viewport) =>
    set((state) => {
      const newState = {
        ...state,
        viewport: viewport,
      };

      saveState(newState);
      return newState;
    }),
}));

export default useDiagramStore;
