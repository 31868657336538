import React, { memo } from "react";
import { Handle, NodeProps, Position } from "@xyflow/react";
import { NodeDefaultInterface } from "../../../interfaces/NodeDefaultInterface";
import colorChangeState from "../../../utils/node/colorChangeState";

const handleStyle = {
  borderRadius: "25%",
  backgroundColor: "#586e7f",
  width: 8,
  height: 8,
};

export default memo((props: NodeProps) => {
  const nodeProps = props as unknown as NodeDefaultInterface;

  const HeightTitle = 32;
  const HeightValue = 24;

  const iconIndexType = (index_type: string, fillColor: string) => {
    switch (index_type) {
      case "primary_key":
        return (
          <svg
            className={`w-3 ${fillColor}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17l0 80c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-40 40 0c13.3 0 24-10.7 24-24l0-40 40 0c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
          </svg>
        );
      case "unique_key":
        return (
          <svg
            className={`w-3 ${fillColor}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M224 0c17.7 0 32 14.3 32 32l0 30.1 15-15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-49 49 0 70.3 61.4-35.8 17.7-66.1c3.4-12.8 16.6-20.4 29.4-17s20.4 16.6 17 29.4l-5.2 19.3 23.6-13.8c15.3-8.9 34.9-3.7 43.8 11.5s3.8 34.9-11.5 43.8l-25.3 14.8 21.7 5.8c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17l-67.7-18.1L287.5 256l60.9 35.5 67.7-18.1c12.8-3.4 26 4.2 29.4 17s-4.2 26-17 29.4l-21.7 5.8 25.3 14.8c15.3 8.9 20.4 28.5 11.5 43.8s-28.5 20.4-43.8 11.5l-23.6-13.8 5.2 19.3c3.4 12.8-4.2 26-17 29.4s-26-4.2-29.4-17l-17.7-66.1L256 311.7l0 70.3 49 49c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15 0 30.1c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-30.1-15 15c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l49-49 0-70.3-61.4 35.8-17.7 66.1c-3.4 12.8-16.6 20.4-29.4 17s-20.4-16.6-17-29.4l5.2-19.3L48.1 395.6c-15.3 8.9-34.9 3.7-43.8-11.5s-3.7-34.9 11.5-43.8l25.3-14.8-21.7-5.8c-12.8-3.4-20.4-16.6-17-29.4s16.6-20.4 29.4-17l67.7 18.1L160.5 256 99.6 220.5 31.9 238.6c-12.8 3.4-26-4.2-29.4-17s4.2-26 17-29.4l21.7-5.8L15.9 171.6C.6 162.7-4.5 143.1 4.4 127.9s28.5-20.4 43.8-11.5l23.6 13.8-5.2-19.3c-3.4-12.8 4.2-26 17-29.4s26 4.2 29.4 17l17.7 66.1L192 200.3l0-70.3L143 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l15 15L192 32c0-17.7 14.3-32 32-32z" />
          </svg>
        );
      case "index":
        return (
          <svg
            className={`w-3 ${fillColor}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M32 32C32 14.3 46.3 0 64 0L320 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-29.5 0 11.4 148.2c36.7 19.9 65.7 53.2 79.5 94.7l1 3c3.3 9.8 1.6 20.5-4.4 28.8s-15.7 13.3-26 13.3L32 352c-10.3 0-19.9-4.9-26-13.3s-7.7-19.1-4.4-28.8l1-3c13.8-41.5 42.8-74.8 79.5-94.7L93.5 64 64 64C46.3 64 32 49.7 32 32zM160 384l64 0 0 96c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-96z" />
          </svg>
        );
      case "null":
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <div className={`relative min-w-32 group shadow-md`} key={nodeProps.id}>
      {/* <Handle
        id={`title-t-${nodeProps.id}`}
        type="source"
        position={Position.Top}
        className={`opacity-0 duration-300 group-hover:opacity-100`}
        style={{
          ...handleStyle,
          left: "50%",
          top: "-4px",
          transform: "translateX(-50%)",
          position: "absolute",
        }}
      /> */}
      <Handle
        id={`title-l-${nodeProps.id}`}
        type="source"
        position={Position.Left}
        className={`opacity-0 duration-300 group-hover:opacity-100`}
        style={{
          ...handleStyle,
          left: "0",
          top: HeightTitle / 2 - 4,
          transform: "translateX(-50%)",
          position: "absolute",
        }}
      />
      <Handle
        id={`title-r-${nodeProps.id}`}
        type="source"
        position={Position.Right}
        className={`opacity-0 duration-300 group-hover:opacity-100`}
        style={{
          ...handleStyle,
          right: "0",
          top: HeightTitle / 2 - 4,
          transform: "translateX(50%)",
          position: "absolute",
        }}
      />
      <div className="bg-nepal-500 text-white font-bold flex flex-col items-center rounded-t-md">
        <span
          className="h-2 w-full rounded-t-md"
          style={{ backgroundColor: nodeProps.data.color }}
        ></span>
        <span className="px-4 py-0.5">{nodeProps.data.title}</span>
      </div>
      {nodeProps.data.values.length > 0 && (
        <div className="bg-gray-300">
          {nodeProps.data.values.map((value, index) => {
            return (
              <React.Fragment key={`${nodeProps.id}-${value.id}`}>
                <div
                  key={`${nodeProps.id}-${value.id}`}
                  className={`flex justify-between gap-4 h-6 px-4 odd:bg-gray-50 even:bg-gray-100 ${
                    colorChangeState(value.changeState).textColor
                  }`}
                >
                  <div className="flex flex-row gap-1 items-center">
                    {value.index_type &&
                      iconIndexType(
                        value.index_type,
                        colorChangeState(value.changeState).fillColor
                      )}
                    <span>{value.name}</span>
                  </div>

                  <span className="opacity-50">
                    {value.type}
                    {value.nullable && <span className="font-black">?</span>}
                  </span>
                </div>

                <Handle
                  id={`body-l-${nodeProps.id}-${value.id}`}
                  type="source"
                  position={Position.Left}
                  className={`opacity-0 duration-300 group-hover:opacity-100`}
                  style={{
                    ...handleStyle,
                    left: "0",
                    top:
                      HeightTitle + HeightValue * index + HeightValue / 2 - 4,
                    transform: "translateX(-50%)",
                    position: "absolute",
                  }}
                />
                <Handle
                  id={`body-r-${nodeProps.id}-${value.id}`}
                  type="source"
                  position={Position.Right}
                  className={`opacity-0 duration-300 group-hover:opacity-100`}
                  style={{
                    ...handleStyle,
                    right: "0",
                    top:
                      HeightTitle + HeightValue * index + HeightValue / 2 - 4,
                    transform: "translateX(50%)",
                    position: "absolute",
                  }}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
});
