import React, { useState, useRef } from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  register: UseFormRegister<any>;
  messageError?: string;
  required?: RegisterOptions["required"];
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultColor?: string;
  iconSVG?: React.ReactNode;
}

const ColorInput = ({
  name,
  label,
  register,
  messageError,
  required,
  disabled = false,
  onChange,
  defaultColor,
  iconSVG,
}: Props) => {
  const [color, setColor] = useState(defaultColor || "#ffffff");
  const cooldownRef = useRef<NodeJS.Timeout | null>(null);

  const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (cooldownRef.current) {
      clearTimeout(cooldownRef.current);
    }

    cooldownRef.current = setTimeout(() => {
      setColor(e.target.value);
      if (onChange) {
        onChange(e);
      }
    }, 50);
  };

  return (
    <>
      <label className="relative mt-1 inline-flex cursor-pointer items-center">
        <input
          id={name}
          type="color"
          className={`sr-only`}
          disabled={disabled}
          {...register(name, { required })}
          onChange={(e) => handleChangeColor(e)}
          defaultValue={color}
        />
        {!iconSVG && (
          <>
            <div
              className="h-4 w-9 rounded-full"
              style={{
                backgroundColor: color,
              }}
            ></div>
            <span className="ml-2 text-sm text-color-gray">
              {`${label} - [${color}]`}{" "}
              <span className="text-red-500">{required && "*"}</span>
            </span>
          </>
        )}
        {iconSVG && <>{iconSVG}</>}
      </label>

      {messageError && (
        <span className="absolute -top-2 left-2 px-1 font-medium text-xs inline-block bg-nepal-200 rounded-md text-gray-900">
          {messageError}
        </span>
      )}
    </>
  );
};

export default ColorInput;
