export default function getCurrentSizeLocalstorage(): number {
  let totalSize = 0,
    itemSize,
    key;
  for (key in localStorage) {
    if (!localStorage.hasOwnProperty(key)) {
      continue;
    }
    itemSize = (localStorage[key].length + key.length) * 2;
    totalSize += itemSize;
    //console.log(key.substring(0, 50) + " = " + (itemSize / 1024).toFixed(2) + " KB");
  }
  //console.log("Total = " + (totalSize / 1024).toFixed(2) + " KB");

  return totalSize / 1024;
}
