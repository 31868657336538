import React from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

interface TextInputProps {
  name: string;
  label: string;
  value?: string;
  defaultValue?: string;
  register: UseFormRegister<any>;
  registerOption?: RegisterOptions;
  messageError?: string;
  disabled?: boolean;
}

const TextInput = ({
  name,
  label,
  value,
  defaultValue,
  register,
  registerOption,
  messageError,
  disabled,
}: TextInputProps) => {
  return (
    <div className="relative">
      {label && (
        <label className="absolute -top-2 left-2 px-1 font-medium text-xs inline-block bg-nepal-200 rounded-md">
          {label}
        </label>
      )}
      <input
        className="block w-full rounded-md border-0 py-1.5 px-1 shadow-sm ring-inset ring-1 ring-gray-500 text-sm border-nepal-950 bg-nepal-200"
        type="text"
        value={value}
        defaultValue={defaultValue}
        {...register(name, registerOption)}
        disabled={disabled}
      />

      {messageError && (
        <span className="absolute -top-2 left-2 px-1 font-medium text-xs inline-block bg-nepal-200 rounded-md ">
          {messageError}
        </span>
      )}
    </div>
  );
};

export default TextInput;
