function func1(num: number) {
  return new Array(num * 1024 + 1).join("a");
}

export default function getMaxSizeLocalstorage(): number {
  // Determine the size of localStorage if it's not set
  if (!localStorage.getItem("size")) {
    let i = 0;
    try {
      // Test up to 10 MB
      for (i; i <= 10000; i += 250) {
        localStorage.setItem("test", func1(i));
      }
    } catch (e) {
      localStorage.removeItem("test");
      localStorage.setItem("size", i ? (i - 250).toString() : "0");
    }
  }

  const size = localStorage.getItem("size");
  if (!size) return 0;

  return parseInt(size);
}
