import { NodeDefaultInterface } from "../../interfaces/NodeDefaultInterface";

const colorChangeState = (
  changeState: NodeDefaultInterface["data"]["values"][0]["changeState"]
): {
  textColor: string;
  fillColor: string;
  borderColor: string;
} => {
  switch (changeState) {
    case "delete":
      return {
        textColor: "text-red-700",
        fillColor: "fill-red-700",
        borderColor: "border-red-700",
      };
    case "update":
      return {
        textColor: "text-yellow-700",
        fillColor: "fill-yellow-700",
        borderColor: "border-yellow-700",
      };
    case "new":
      return {
        textColor: "text-green-700",
        fillColor: "fill-green-700",
        borderColor: "border-green-700",
      };
    default:
      return {
        textColor: "text-nepal-950",
        fillColor: "fill-nepal-950",
        borderColor: "border-nepal-950",
      };
  }
};

export default colorChangeState;
