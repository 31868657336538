import React from "react";
import useStore from "../store/useStore";
import Header from "../components/Header";

const HomePage = () => {
  const { count, increment, decrement } = useStore((state) => state);

  return (
    <div>
      <Header />

      <h1 className="text-2xl font-bold">Page d'accueil</h1>
      <p>Bienvenue sur la page d'accueil !</p>
      <div className="mt-4">
        <p>Count: {count}</p>
        <button
          className="mr-2 px-4 py-2 bg-blue-500 text-white rounded"
          onClick={increment}
        >
          Increment
        </button>
        <button
          className="px-4 py-2 bg-red-500 text-white rounded"
          onClick={decrement}
        >
          Decrement
        </button>
      </div>
    </div>
  );
};

export default HomePage;
