import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center px-4 h-16 bg-nepal-500">
      <h1 className="text-2xl font-bold">Diagram UML</h1>

      <span>Version : {process.env.REACT_APP_VERSION}</span>

      <div className="flex items-center gap-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={() => navigate("/")}
        >
          Accueil
        </button>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={() => navigate("/diagram")}
        >
          Diagram
        </button>
      </div>
    </div>
  );
};

export default Header;
