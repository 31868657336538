import TextInput from "../../inputs/TextInput";
import { FormNodeHeaderProps } from "./types";

const NodeHeader = ({
  nodeId,
  nodeTitle,
  register,
  editTitle,
  setEditTitle,
  isExpanded,
  setIsExpanded,
  onFocusNode,
}: FormNodeHeaderProps) => {
  const handleFocusNode = () => {
    onFocusNode(nodeId);
  };

  return (
    <div className="bg-nepal-500 h-12 w-full px-4 flex flex-row justify-between items-center">
      {editTitle ? (
        <div className="flex flex-col justify-center items-start z-20">
          <TextInput
            name="data.title"
            label=""
            defaultValue={nodeTitle}
            register={register}
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center items-start">
          <span className="text-xs text-white opacity-50">{nodeId}</span>
          <span className="text-lg font-semibold text-white -mt-2">
            {nodeTitle}
          </span>
        </div>
      )}

      <div className="flex flex-row gap-2">
        <button
          type="button"
          onClick={() => setEditTitle(!editTitle)}
          className="flex items-center justify-center w-6 h-6 transition-transform duration-300 hover:scale-125"
        >
          <svg
            className="w-4 h-4 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z" />
          </svg>
        </button>

        <button
          type="button"
          onClick={() => handleFocusNode()}
          className="flex items-center justify-center w-6 h-6 transition-transform duration-300 hover:scale-125"
        >
          <svg
            className="w-4 h-4 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M256 0c17.7 0 32 14.3 32 32l0 32 32 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-64 64c-12.5 12.5-32.8 12.5-45.3 0l-64-64c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l32 0 0-32c0-17.7 14.3-32 32-32zM169.4 393.4l64-64c12.5-12.5 32.8-12.5 45.3 0l64 64c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8l-32 0 0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32-32 0c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9zM32 224l32 0 0-32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c12.5 12.5 12.5 32.8 0 45.3l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-32-32 0c-17.7 0-32-14.3-32-32s14.3-32 32-32zm297.4 54.6c-12.5-12.5-12.5-32.8 0-45.3l64-64c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 32 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-32 0 0 32c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-64-64zM256 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
          </svg>
        </button>

        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className={`flex items-center justify-center w-6 h-6 transition-transform duration-300 hover:scale-125 ${
            isExpanded ? "-rotate-180" : ""
          }`}
        >
          <svg
            className="w-6 h-6 fill-white"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NodeHeader;
