import { memo, useMemo, useEffect } from "react";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from "@xyflow/react";
import { EdgeDefaultInterface } from "../../../interfaces/EdgeDefaultInterface";
import useDiagramStore from "../../../store/useDiagram";

interface EdgeTableProps extends EdgeDefaultInterface {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition: any;
  targetPosition: any;
  selected?: boolean;
}

const EdgeTable = (props: EdgeProps) => {
  const edgeProps = props as unknown as EdgeTableProps;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: edgeProps.sourceX,
    sourceY: edgeProps.sourceY,
    sourcePosition: edgeProps.sourcePosition,
    targetX: edgeProps.targetX,
    targetY: edgeProps.targetY,
    targetPosition: edgeProps.targetPosition,
  });

  const { removeEdge, updateEdge, getEdge } = useDiagramStore((state) => state);

  const handleUpdateEdge = () => {
    const edge = getEdge(edgeProps.id);
    if (!edge) return;

    updateEdge(edgeProps.id, {
      ...edge,
      source: edge.target,
      target: edge.source,
      sourceHandle: edge.targetHandle,
      targetHandle: edge.sourceHandle,
    });
  };

  const sourcePositionRotated = useMemo(() => {
    switch (edgeProps.sourcePosition) {
      case "top":
        return "rotate-0";
      case "bottom":
        return "rotate-180";
      case "left":
        return "-rotate-90";
      case "right":
        return "rotate-90";
      default:
        return "rotate-0";
    }
  }, [edgeProps.sourcePosition]);

  return (
    <>
      <BaseEdge
        id={edgeProps.id}
        path={edgePath}
        style={{ zIndex: edgeProps.selected ? 10000 : 0 }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-60%, -50%) translate(${edgeProps.sourceX}px,${edgeProps.sourceY}px)`,
          }}
          className="absolute z-30"
        >
          <svg
            className={`w-6 h-6 ${sourcePositionRotated}`}
            style={{
              fill: edgeProps.selected ? "#555" : "#b1b1b7",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
          </svg>
        </div>

        {edgeProps.selected && (
          <div
            style={{
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              pointerEvents: "all",
            }}
            className="flex justify-center items-center gap-2 absolute z-30"
          >
            <button
              className="cursor-pointer bg-nepal-100 hover:bg-nepal-200 hover:scale-110 duration-300 shadow-md p-1.5 rounded-full"
              type="button"
              onClick={() => removeEdge(edgeProps.id)}
            >
              <svg
                className="w-3 h-3 fill-red-700"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
              </svg>
            </button>

            <button
              className="cursor-pointer bg-nepal-100 hover:bg-nepal-200 hover:scale-110 duration-300 shadow-md p-1.5 rounded-full"
              type="button"
              onClick={() => handleUpdateEdge()}
            >
              <svg
                className="w-3 h-3 fill-nepal-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M125.7 160l50.3 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L48 224c-17.7 0-32-14.3-32-32L16 64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z" />
              </svg>
            </button>
          </div>
        )}

        <div
          style={{
            transform: `translate(-50%, -50%) translate(${edgeProps.targetX}px,${edgeProps.targetY}px)`,
          }}
          className="absolute z-30"
        >
          <svg
            className={`w-4 h-4 ${sourcePositionRotated}`}
            style={{
              fill: edgeProps.selected ? "#555" : "#b1b1b7",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
          </svg>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default memo(EdgeTable);
