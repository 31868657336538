import { useEffect, useRef, useState, useCallback } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { NodeDefaultInterface } from "../../../interfaces/NodeDefaultInterface";
import { FormNodeProps } from "./types";
import NodeHeader from "./NodeHeader";
import NodeContent from "./NodeContent";
import NodeFooter from "./NodeFooter";

const FormNode = ({
  nodeIndex,
  node,
  onUpdateNode,
  onDeleteNode,
  onAddColumn,
  onRemoveColumn,
  onFlowFocusNode,
  openNodeClick,
  onDragStart,
  onDragEnter,
  onDragEnd,
  onDragOver,
}: FormNodeProps) => {
  const { setValue, register, handleSubmit, watch, reset } =
    useForm<NodeDefaultInterface>({
      defaultValues: node,
    });

  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isExpandedAnimation, setIsExpandedAnimation] =
    useState<boolean>(false);
  const [editTitle, setEditTitle] = useState<boolean>(false);

  const onSubmit: SubmitHandler<NodeDefaultInterface> = (data) =>
    console.log(data);

  const handleRemoveColumn = useCallback(
    (columnId: string) => onRemoveColumn(node.id, columnId),
    [node.id, onRemoveColumn]
  );

  const handleDeleteNode = useCallback(
    () => onDeleteNode(node.id),
    [node.id, onDeleteNode]
  );

  const handleAddColumn = useCallback(
    () => onAddColumn(node.id),
    [node.id, onAddColumn]
  );

  const handleOrderColumn = useCallback(
    (newColumns: NodeDefaultInterface["data"]["values"]) => {
      onUpdateNode({
        ...node,
        data: {
          ...node.data,
          values: newColumns,
        },
      });
    },
    [node.data.values, onUpdateNode]
  );

  const handleToggleExpand = () => {
    setIsExpandedAnimation(true);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!contentRef.current) return;
    if (isExpanded) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0";
    }
  }, [isExpanded, node]);

  useEffect(() => {
    if (isExpanded) {
      setIsExpandedAnimation(true);
    } else {
      setTimeout(() => {
        setIsExpandedAnimation(false);
      }, 300);
    }
  }, [isExpanded]);

  useEffect(() => {
    reset(node); // reset form when node changes
  }, [node, reset]);

  useEffect(() => {
    const subscription = watch((value: any) => {
      const typedValue = value as NodeDefaultInterface;
      onUpdateNode(typedValue);
    });
    return () => subscription.unsubscribe();
  }, [watch, onUpdateNode]);

  useEffect(() => {
    if (openNodeClick === node.id) {
      handleToggleExpand();
      if (headerRef.current) {
        const timeout = setTimeout(() => {
          headerRef?.current?.scrollIntoView({ behavior: "smooth" });
        }, 250);
        return () => clearTimeout(timeout);
      }
    }
  }, [openNodeClick, node.id, headerRef]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div ref={headerRef} className="flex flex-row w-full" key={node.id}>
        <div className="w-2" style={{ backgroundColor: node.data.color }} />

        <div className="w-full overflow-hidden">
          <div
            onDragStart={() => onDragStart(nodeIndex)}
            onDragEnter={() => onDragEnter(nodeIndex)}
            onDragEnd={() => onDragEnd()}
            onDragOver={(e) => onDragOver(e)}
            draggable
          >
            <NodeHeader
              nodeId={node.id}
              nodeTitle={node.data.title}
              register={register}
              editTitle={editTitle}
              setEditTitle={setEditTitle}
              isExpanded={isExpanded}
              setIsExpanded={handleToggleExpand}
              onFocusNode={onFlowFocusNode}
            />
          </div>

          <div
            ref={contentRef}
            className={`bg-nepal-200 overflow-hidden transition-all duration-300 max-h-0 ${
              isExpanded ? "max-h-fit" : "max-h-0"
            }`}
          >
            <div className={`py-4 flex flex-col gap-4`}>
              {isExpandedAnimation && (
                <>
                  <NodeContent
                    nodeId={node.id}
                    nodeValues={node.data.values}
                    register={register}
                    handleOrderColumn={handleOrderColumn}
                    handleRemoveColumn={handleRemoveColumn}
                  />

                  <div className="bg-nepal-900 h-0.5 w-full opacity-15" />

                  <NodeFooter
                    node={node}
                    register={register}
                    handleDeleteNode={handleDeleteNode}
                    handleAddColumn={handleAddColumn}
                    onChangeColor={(color) => setValue("data.color", color)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormNode;
