import React from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

interface BooleanInputProps {
  name: string;
  label: string;
  value?: boolean;
  defaultValue?: boolean;
  register: UseFormRegister<any>;
  registerOption?: RegisterOptions;
  messageError?: string;
  disabled?: boolean;
}

const BooleanInput = ({
  name,
  label,
  value,
  defaultValue,
  register,
  registerOption,
  messageError,
  disabled = false,
}: BooleanInputProps) => {
  return (
    <div className="relative flex items-center">
      <label
        htmlFor={name}
        className="relative inline-flex cursor-pointer items-center"
      >
        <input
          id={name}
          type="checkbox"
          className="peer sr-only"
          defaultChecked={defaultValue}
          checked={value}
          {...register(name, registerOption)}
          disabled={disabled}
        />
        <div className="peer h-4 w-11 rounded-full border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-300 peer-checked:after:translate-x-full peer-focus:ring-green-300"></div>
        {label && (
          <label htmlFor={name} className="ml-2 block text-sm text-gray-900">
            {label}
          </label>
        )}
      </label>
      {messageError && (
        <span className="absolute -top-2 left-2 px-1 font-medium text-xs inline-block bg-nepal-200 rounded-md text-gray-900">
          {messageError}
        </span>
      )}
    </div>
  );
};

export default BooleanInput;
