import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactFlowProvider, Viewport } from "@xyflow/react";
import Header from "../components/Header";
import useDiagramStore from "../store/useDiagram";
import DiagramFlow from "../components/flow/DiagramFlow";
import FormNode from "../components/form/FormNode/FormNode";
import { NodeDefaultInterface } from "../interfaces/NodeDefaultInterface";
import { PopoverProvider } from "../components/PopoverContext";
import PopoverPanel from "../components/Popover";
import FormSqlParse from "../components/form/FormSqlParse";

const DiagramPage = () => {
  const {
    initialNodes,
    addNode,
    updateNode,
    removeNode,
    addColumnNode,
    removeColumnNode,
    updateViewport,
  } = useDiagramStore((state) => state);
  const nodes = useDiagramStore((state) => state.nodes);
  const viewport = useDiagramStore((state) => state.viewport);

  const [sectionNode, setSectionNode] = useState<NodeDefaultInterface[]>(nodes);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [clickNodeID, setClickNodeID] = useState<string | null>(null);
  const [focusFlowNodeID, setFocusFlowNodeID] = useState<string | null>(null);
  const [localViewport, setLocalViewport] = useState<Viewport | undefined>(
    viewport
  );
  const cooldownUpdateViewportRef = useRef<NodeJS.Timeout | null>(null);
  const cooldownUpdateNodeRef = useRef<NodeJS.Timeout | null>(null);

  const handleUpdateNode = useCallback(
    (id: string, node: NodeDefaultInterface) => {
      if (cooldownUpdateNodeRef.current) {
        clearTimeout(cooldownUpdateNodeRef.current);
      }

      cooldownUpdateNodeRef.current = setTimeout(() => {
        updateNode(id, node);
      }, 500);
    },
    [updateNode]
  );

  const handleRemoveNode = useCallback(
    (id: string) => {
      removeNode(id);
    },
    [removeNode]
  );

  const handleAddColumn = useCallback(
    (id: string) => {
      addColumnNode(id);
    },
    [addColumnNode]
  );

  const handleRemoveColumn = useCallback(
    (nodeid: string, columnid: string) => {
      removeColumnNode(nodeid, columnid);
    },
    [removeColumnNode]
  );

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const handleDragEnter = (index: number) => {
    if (draggedIndex === null || index === draggedIndex) return;

    const newNodes = [...sectionNode];
    const draggedNode = newNodes[draggedIndex];
    newNodes.splice(draggedIndex, 1);
    newNodes.splice(index, 0, draggedNode);

    setSectionNode(newNodes);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    initialNodes(sectionNode);
    setDraggedIndex(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!localViewport) return;
    if (cooldownUpdateViewportRef.current) {
      clearTimeout(cooldownUpdateViewportRef.current);
    }

    cooldownUpdateViewportRef.current = setTimeout(() => {
      updateViewport(localViewport);
    }, 500);
  }, [localViewport]);

  useEffect(() => {
    setSectionNode(nodes);
  }, [nodes]);

  return (
    <ReactFlowProvider>
      <PopoverProvider>
        {/*       <FpsView /> */}
        <div className="flex flex-col h-screen">
          <Header />

          <div className="flex flex-row flex-1 w-full overflow-hidden">
            <div className="flex flex-col bg-nepal-300 min-w-[400px] w-[400px]">
              <div className="flex-shrink-0 flex flex-row justify-between items-center px-4 h-16">
                <h1 className="text-2xl font-bold">Tables</h1>

                <button
                  className="bg-nepal-500 text-white rounded-full px-4 py-2 text-sm flex flex-row gap-2 justify-center items-center"
                  type="button"
                  onClick={addNode}
                >
                  <svg
                    className="w-4 h-4 fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                  </svg>
                  Nouveau noeud
                </button>
              </div>

              <div className="overflow-auto">
                {sectionNode.map((node, idx) => (
                  <FormNode
                    key={node.id}
                    nodeIndex={idx}
                    node={node}
                    openNodeClick={clickNodeID}
                    onUpdateNode={(updatedNode) =>
                      handleUpdateNode(node.id, updatedNode)
                    }
                    onDeleteNode={() => handleRemoveNode(node.id)}
                    onAddColumn={() => handleAddColumn(node.id)}
                    onRemoveColumn={(nodeId, columnId) =>
                      handleRemoveColumn(nodeId, columnId)
                    }
                    onFlowFocusNode={(nodeId) => setFocusFlowNodeID(nodeId)}
                    onDragStart={(index) => handleDragStart(index)}
                    onDragEnter={(index) => handleDragEnter(index)}
                    onDragEnd={() => handleDragEnd()}
                    onDragOver={(e) => handleDragOver(e)}
                  />
                ))}
              </div>
            </div>

            <div className="relative flex-1 bg-nepal-100">
              <DiagramFlow
                focusNodeId={focusFlowNodeID}
                onChangeViewport={(e) => setLocalViewport(e)}
                onNodeClick={(e, node) => setClickNodeID(node.id)}
              />

              <div className="absolute top-4 left-4 flex flex-row gap-4">
                {localViewport && (
                  <>
                    <div className="px-2 py-1 bg-nepal-300 rounded-full">
                      <span>
                        {`x: ${localViewport.x.toFixed(
                          0
                        )} / y: ${localViewport.y.toFixed(0)}`}
                      </span>
                    </div>

                    <div className="flex flex-row px-2 py-1 gap-2 bg-nepal-300 rounded-full">
                      <span>zoom: {localViewport.zoom.toFixed(2)}</span>
                    </div>

                    <div className="px-2 py-1 bg-nepal-300 rounded-full">
                      <div className="flex flex-row justify-center items-center gap-2">
                        Import SQL
                        <PopoverPanel
                          id={`popover-import`}
                          trigger={
                            <svg
                              className="w-4 h-4 fill-nepal-800"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 128 512"
                            >
                              <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                            </svg>
                          }
                          openTrigger={
                            <svg
                              className={`h-6 fill-nepal-800 rotate-90`}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                            >
                              <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
                            </svg>
                          }
                          position="bottom"
                        >
                          <FormSqlParse />
                        </PopoverPanel>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </PopoverProvider>
    </ReactFlowProvider>
  );
};

export default DiagramPage;
