import ColorInput from "../../inputs/ColorInput";
import { FormNodeFooterProps } from "./types";

const NodeFooter = ({
  node,
  register,
  handleDeleteNode,
  handleAddColumn,
  onChangeColor,
}: FormNodeFooterProps) => {
  return (
    <div className="px-4 flex flex-row justify-between items-center">
      <div className="flex flex-row gap-4">
        <button type="button" onClick={handleDeleteNode}>
          <svg
            className="w-6 h-6 fill-red-700"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
          </svg>
        </button>

        <ColorInput
          name="data.color"
          label="Couleur"
          defaultColor={node.data.color}
          register={register}
          onChange={(e) => onChangeColor(e.target.value)}
          iconSVG={
            <svg
              className="w-6 h-6 fill-nepal-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M0 32C0 14.3 14.3 0 32 0L160 0c17.7 0 32 14.3 32 32l0 384c0 53-43 96-96 96s-96-43-96-96L0 32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9l0-262 75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192L480 320c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32l-297.2 0zM128 64L64 64l0 64 64 0 0-64zM64 192l0 64 64 0 0-64-64 0zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
            </svg>
          }
        />
      </div>

      <div className="flex flex-row gap-4">
        <button
          className="bg-nepal-500 text-white rounded-full px-4 py-2 text-sm"
          type="button"
          onClick={handleAddColumn}
        >
          Nouvelle colonne
        </button>
      </div>
    </div>
  );
};

export default NodeFooter;
