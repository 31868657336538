import React, { useState, useRef, useEffect } from "react";
import { RegisterOptions, UseFormRegister } from "react-hook-form";

interface Option {
  value: string;
  label: string;
  iconSVG?: React.ReactNode;
}

interface CustomSelectProps {
  name: string;
  label: string;
  options: Option[];
  register: UseFormRegister<any>;
  registerOption?: RegisterOptions;
  messageError?: string;
  disabled?: boolean;
  defaultValue?: string;
}

const CustomSelect = ({
  name,
  label,
  options,
  register,
  registerOption,
  messageError,
  disabled = false,
  defaultValue,
}: CustomSelectProps) => {
  const [selected, setSelected] = useState(defaultValue || "");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const { ref, onChange, ...rest } = register(name, registerOption);

  const handleOptionClick = (value: string) => {
    setSelected(value);
    setIsOpen(false);
    onChange({ target: { value, name } });
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  return (
    <div className="relative" ref={selectRef}>
      {label && (
        <label className="absolute -top-2 left-2 px-1 font-medium text-xs inline-block bg-nepal-200 rounded-md text-gray-900">
          {label}
        </label>
      )}
      <div
        className="block w-full rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-inset ring-1 ring-gray-500 text-sm border-nepal-950 bg-nepal-200 cursor-pointer"
        onClick={() => !disabled && setIsOpen((prev) => !prev)}
      >
        <span className="flex items-center justify-between">
          <span>
            {options.find((option) => option.value === selected)?.label || ""}
          </span>
          <svg
            className={`ml-2 h-5 w-5 duration-300 fill-gray-400 ${
              isOpen ? "-rotate-180" : ""
            }`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z" />
          </svg>
        </span>
      </div>
      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg">
          {options.map((option) => (
            <li
              key={option.value}
              className="flex items-center p-1 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option.value)}
            >
              {option.iconSVG && <span className="mr-2">{option.iconSVG}</span>}
              {option.label}
            </li>
          ))}
        </ul>
      )}
      <input
        type="hidden"
        value={selected}
        {...rest}
        ref={(e) => {
          ref(e);
          // Si par hasard il y a besoin d'un attachment pour que react-hook-form connaisse la référence.
        }}
        disabled={disabled}
      />
      {messageError && (
        <span className="absolute -top-2 left-2 px-1 font-medium text-xs inline-block bg-nepal-200 rounded-md text-gray-900">
          {messageError}
        </span>
      )}
    </div>
  );
};

export default CustomSelect;
