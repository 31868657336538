import { FormNodeContentProps } from "./types";
import NodeContentRow from "./NodeContentRow";
import { NodeDefaultInterface } from "../../../interfaces/NodeDefaultInterface";
import { useEffect, useState } from "react";

const NodeContent = ({
  nodeId,
  nodeValues,
  register,
  handleOrderColumn,
  handleRemoveColumn,
}: FormNodeContentProps) => {
  const [columnsNode, setColumnsNode] =
    useState<NodeDefaultInterface["data"]["values"]>(nodeValues);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  useEffect(() => {
    setColumnsNode(nodeValues);
  }, [nodeValues]);

  const handleDragStart = (index: number) => {
    setDraggedIndex(index);
    console.log("handleDragStart", index);
  };

  const handleDragEnter = (index: number) => {
    if (draggedIndex === null || index === draggedIndex) return;
    console.log("handleDragEnter", index);

    const newColumns = [...columnsNode];
    const draggedColumn = newColumns[draggedIndex];
    newColumns.splice(draggedIndex, 1);
    newColumns.splice(index, 0, draggedColumn);

    //handleOrderColumn(draggedColumn.id, index);

    setColumnsNode(newColumns);
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    handleOrderColumn(columnsNode);
    setDraggedIndex(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <div className="px-4 w-full">
      <div className="flex flex-col gap-2">
        {columnsNode.map((value, index) => (
          <div
            key={`${nodeId}-value-${value.id}`}
            onDragStart={() => handleDragStart(index)}
            onDragEnter={() => handleDragEnter(index)}
            onDragEnd={() => handleDragEnd()}
            onDragOver={(e) => handleDragOver(e)}
            draggable
          >
            <NodeContentRow
              key={`${nodeId}-value-${value.id}`}
              nodeId={nodeId}
              rowIndex={index}
              rowValue={value}
              register={register}
              handleRemoveColumn={handleRemoveColumn}
              enableDrag={draggedIndex !== null}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NodeContent;
