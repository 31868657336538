import { useForm } from "react-hook-form";
import { NodeDefaultInterface } from "../../interfaces/NodeDefaultInterface";
import useDiagramStore from "../../store/useDiagram";
import { EdgeDefaultInterface } from "../../interfaces/EdgeDefaultInterface";

interface FormSqlParseProps {
  sql: string;
}

interface Constraint {
  name: string;
  type: "FOREIGN KEY" | "PRIMARY KEY" | "UNIQUE" | "CHECK";
  columns: string[];
  referencedTable?: string;
  referencedColumns?: string[];
}

const FormSqlParse = () => {
  const { initialNodes, initialEdges } = useDiagramStore((state) => state);
  const { register, handleSubmit } = useForm<FormSqlParseProps>();

  const onSubmit = (data: any) => {
    const TableAndColumns = parseTableAndColumnsSQL(data.sql);
    const Index = parseIndexSQL(data.sql, TableAndColumns.nodes);
    initialNodes(Index);

    const ContraintesEdges = parseContraintesSQL(data.sql, Index);
    initialEdges(ContraintesEdges);
  };

  function parseTableAndColumnsSQL(sqlQuery: string) {
    const tableRegex = /CREATE TABLE\s+`?(\w+)`?\s*\(([\s\S]*?)\)\s*ENGINE/gi;
    const columnRegex = /`(\w+)`\s+([\w()]+)(\s+NOT NULL)?/g;

    const nodes = [];

    let match;
    while ((match = tableRegex.exec(sqlQuery)) !== null) {
      let node: NodeDefaultInterface;

      const tableName = match[1];
      const columnsString = match[2];

      node = {
        id: Math.random().toString(36).substring(2, 15), // Fonction pour générer un ID unique
        type: "table",
        data: {
          title: tableName,
          color: "#ffffff",
          values: [],
        },
        position: { x: 0, y: 0 }, // Position aléatoire pour illustration
      };

      let columnMatch;
      while ((columnMatch = columnRegex.exec(columnsString)) !== null) {
        node.data.values.push({
          id: Math.random().toString(36).substring(2, 15),
          name: columnMatch[1],
          type: columnMatch[2],
          nullable: columnMatch[3]
            ? !columnMatch[3].includes("NOT NULL")
            : false,
          index_type: null, // À ajuster pour gérer les index
          index_key: null,
          changeState: null,
        });
      }

      nodes.push(node);
    }

    return { nodes };
  }

  function parseIndexSQL(sqlQuery: string, nodes: NodeDefaultInterface[]) {
    const newNodes: NodeDefaultInterface[] = nodes;
    const tableRegex =
      /--\s*Index pour la table `(\w+)`\s*--([\s\S]*?)(?=\s*--\s*Index pour la table|$)/g;
    const indexRegex =
      /ADD\s+(PRIMARY KEY|UNIQUE(?: KEY)?|(?:FULLTEXT |SPATIAL )?(?:KEY|INDEX))(?:\s+`?(\w+)`?)?\s*\(([^)]+)\)/g;

    let tableMatch;
    while ((tableMatch = tableRegex.exec(sqlQuery)) !== null) {
      const tableName = tableMatch[1];
      const indexDefinitions = tableMatch[2];

      const node = nodes.find((node) => node.data.title === tableName);
      if (!node) continue;

      const nodeIndex = newNodes.indexOf(node);
      if (nodeIndex === -1) continue;

      let indexMatch;
      while ((indexMatch = indexRegex.exec(indexDefinitions)) !== null) {
        let indexType: string | null;
        switch (indexMatch[1]) {
          case "PRIMARY KEY":
            indexType = "primary_key";
            break;
          case "UNIQUE KEY":
            indexType = "unique_key";
            break;
          case "KEY":
            indexType = "index";
            break;
          default:
            indexType = null;
            break;
        }

        const indexName =
          indexMatch[2] || (indexType === "primary_key" ? null : null);
        const columns = indexMatch[3]
          .split(",")
          .map((col) => col.trim().replace(/`/g, ""));

        for (const column of columns) {
          const nodeValue = node.data.values.find(
            (value) => value.name === column
          );
          if (!nodeValue) continue;

          const nodeIndexValue = node.data.values.indexOf(nodeValue);
          if (nodeIndexValue === -1) continue;

          node.data.values[nodeIndexValue].index_type = indexType;
          node.data.values[nodeIndexValue].index_key = indexName;
        }

        newNodes[nodeIndex] = node;
      }
    }

    return newNodes;
  }

  function parseContraintesSQL(
    sqlQuery: string,
    nodes: NodeDefaultInterface[]
  ) {
    const newNodes: NodeDefaultInterface[] = nodes;
    const newEdges: EdgeDefaultInterface[] = [];
    const tableRegex =
      /--\s*Contraintes pour la table `(\w+)`\s*--\s*ALTER TABLE `\w+`\s*([\s\S]*?)(?=\s*--|$)/g;
    const constraintRegex =
      /ADD CONSTRAINT `(\w+)`\s+(FOREIGN KEY|PRIMARY KEY|UNIQUE|CHECK)\s*\(([^)]+)\)(?:\s+REFERENCES\s+`(\w+)`\s*\(([^)]+)\))?/g;

    let tableMatch;
    while ((tableMatch = tableRegex.exec(sqlQuery)) !== null) {
      const tableName = tableMatch[1];
      const constraintDefinitions = tableMatch[2];

      let constraintMatch;
      while (
        (constraintMatch = constraintRegex.exec(constraintDefinitions)) !== null
      ) {
        const constraint: Constraint = {
          name: constraintMatch[1],
          type: constraintMatch[2] as Constraint["type"],
          columns: constraintMatch[3]
            .split(",")
            .map((col) => col.trim().replace(/`/g, "")),
        };

        if (
          constraint.type === "FOREIGN KEY" &&
          constraintMatch[4] &&
          constraintMatch[5]
        ) {
          constraint.referencedTable = constraintMatch[4];
          constraint.referencedColumns = constraintMatch[5]
            .split(",")
            .map((col) => col.trim().replace(/`/g, ""));
        }

        // Trouver la table de départ et la table cible
        const sourceTable = tableName;
        const sourceTableNode = newNodes.find(
          (node) => node.data.title === sourceTable
        );
        if (!sourceTableNode) continue;

        const targetTable = constraint.referencedTable;
        const targetTableNode = newNodes.find(
          (node) => node.data.title === targetTable
        );
        if (!targetTableNode) continue;

        // Trouver les colonnes de départ et cible
        const sourceColumns = constraint.columns;
        const sourceColumnsNode = sourceTableNode.data.values.find(
          (value) => value.name === sourceColumns[0]
        );
        if (!sourceColumnsNode) continue;

        const targetColumns = constraint.referencedColumns;
        const targetColumnsNode = targetTableNode.data.values.find(
          (value) => value.name === targetColumns?.[0]
        );
        if (!targetColumnsNode) continue;

        const sourceHandle = `body-l-${sourceTableNode.id}-${sourceColumnsNode.id}`;
        const targetHandle = `body-r-${targetTableNode.id}-${targetColumnsNode.id}`;

        // Créer la contrainte
        newEdges.push({
          id: Math.random().toString(36).substring(2, 15),
          type: "table",
          source: sourceTableNode.id,
          target: targetTableNode.id,
          sourceHandle: sourceHandle,
          targetHandle: targetHandle,
        });
      }
    }

    return newEdges;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-2 justify-start items-start p-4">
        <h3 className="font-medium mb-1">Import SQL :</h3>
        <textarea
          {...register("sql")}
          placeholder="sql"
          className="w-[512px] h-[512px] rounded-md border-0 py-1.5 px-1 text-gray-900 shadow-sm ring-inset ring-1 ring-gray-500 text-sm border-nepal-950 bg-nepal-200"
        />

        <button
          type="submit"
          className="bg-nepal-500 text-white rounded-full px-4 py-2 text-sm"
        >
          Parse
        </button>
      </div>
    </form>
  );
};

export default FormSqlParse;
