import React, { useEffect, useRef } from "react";
import { usePopover } from "./PopoverContext";

interface PopoverPanelProps {
  children: React.ReactNode;
  trigger: React.ReactNode;
  openTrigger?: React.ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  id: string;
}

const PopoverPanel: React.FC<PopoverPanelProps> = ({
  children,
  trigger,
  openTrigger,
  position = "bottom",
  id,
}) => {
  const { openPopover, closePopover, isOpen } = usePopover();
  const triggerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const updatePanelPosition = () => {
    if (triggerRef.current && panelRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const panelRect = panelRef.current.getBoundingClientRect();

      let top = 0;
      let left = 0;

      switch (position) {
        case "top":
          top = triggerRect.top - panelRect.height;
          left = triggerRect.left;
          break;
        case "bottom":
          top = triggerRect.bottom;
          left = triggerRect.left;
          break;
        case "left":
          top = triggerRect.top;
          left = triggerRect.left - panelRect.width;
          break;
        case "right":
          top = triggerRect.top;
          left = triggerRect.right;
          break;
        default:
          top = triggerRect.bottom;
          left = triggerRect.left;
          break;
      }

      // Adjust position if out of view
      if (top < 0) top = 0;
      if (left < 0) left = 0;
      if (top + panelRect.height > window.innerHeight)
        top = window.innerHeight - panelRect.height;
      if (left + panelRect.width > window.innerWidth)
        left = window.innerWidth - panelRect.width;

      panelRef.current.style.top = `${top}px`;
      panelRef.current.style.left = `${left}px`;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        closePopover(id);
      }
    };

    if (isOpen(id)) {
      updatePanelPosition();
      window.addEventListener("resize", updatePanelPosition);
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("resize", updatePanelPosition);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen(id), position]);

  const togglePanel = () => {
    if (isOpen(id)) {
      closePopover(id);
    } else {
      openPopover(id);
    }
  };

  return (
    <div className="relative">
      <div
        ref={triggerRef}
        onClick={togglePanel}
        className="cursor-pointer transition-transform duration-300 hover:scale-125"
      >
        {openTrigger ? (isOpen(id) ? openTrigger : trigger) : trigger}
      </div>
      {isOpen(id) && (
        <div
          ref={panelRef}
          className="absolute bg-white border rounded shadow-lg z-10"
          style={{ position: "fixed" }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default PopoverPanel;
