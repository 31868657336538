import React, { useCallback, useEffect } from "react";
import {
  addEdge,
  Background,
  BackgroundVariant,
  ConnectionMode,
  NodeChange,
  ReactFlow,
  useEdgesState,
  useNodesState,
  useOnViewportChange,
  useReactFlow,
  useStoreApi,
  Viewport,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import useDiagramStore from "../../store/useDiagram";
import FlowNodeTable from "./nodes/NodeTable";
import FlowEdgeTable from "./edges/EdgeTable";

const nodeTypes = { table: FlowNodeTable };
const edgeTypes = { table: FlowEdgeTable };

interface DiagramFlowProps {
  focusNodeId: string | null;
  onChangeViewport: (viewport: Viewport) => void;
  onNodeClick?: (e: any, node: any) => void;
}

const DiagramFlow = ({
  focusNodeId,
  onChangeViewport,
  onNodeClick,
}: DiagramFlowProps) => {
  const { updateNode, addEdge: addStoreEdge } = useDiagramStore(
    (state) => state
  );
  const diagramNodes = useDiagramStore((state) => state.nodes);
  const diagramEdges = useDiagramStore((state) => state.edges);
  const viewport = useDiagramStore((state) => state.viewport);

  const [nodes, setNodes, onNodesChange] = useNodesState(diagramNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(diagramEdges);
  const store = useStoreApi();
  const { setCenter } = useReactFlow();

  const onConnect = useCallback((params: any) => {
    setEdges((els) => addEdge(params, els));
    addStoreEdge(params);
  }, []);

  const onNodesChangeData = useCallback(
    (changes: NodeChange<any>[]) => {
      onNodesChange(changes);
      changes.forEach((change) => {
        if (change.type === "position" && !change.dragging) {
          const node = diagramNodes.find((n) => n.id === change.id);
          if (node) {
            updateNode(change.id, { ...node, position: change.position! });
          }
        }
      });
    },
    [onNodesChange, diagramNodes, updateNode]
  );

  useOnViewportChange({
    onChange: (viewport: Viewport) => {
      onChangeViewport(viewport);
    },
    onEnd: (viewport: Viewport) => {
      onChangeViewport(viewport);
    },
  });

  useEffect(() => {
    setNodes(diagramNodes);
    setEdges(diagramEdges);
  }, [diagramNodes, diagramEdges]);

  useEffect(() => {
    if (focusNodeId) {
      const { nodeLookup } = store.getState();

      const node = Array.from(nodeLookup.values()).find(
        (node) => node.id === focusNodeId
      );

      if (!node || !node.measured) return;
      if (
        typeof node.measured.width === "undefined" ||
        typeof node.measured.height === "undefined"
      )
        return;

      const x = node.position.x + node.measured.width / 2;
      const y = node.position.y + node.measured.height / 2;

      setCenter(x, y, { zoom: 2, duration: 300 });
    }
  }, [focusNodeId]);

  useEffect(() => {
    if (!viewport) return;
    onChangeViewport(viewport);
  }, []);

  return (
    <div className="w-full h-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChangeData}
        onEdgesChange={onEdgesChange}
        onNodeClick={onNodeClick}
        onConnect={onConnect}
        connectionMode={ConnectionMode.Loose}
        connectionLineStyle={{ stroke: "#586e7f" }}
        snapGrid={[16, 16]}
        snapToGrid
        minZoom={0.2}
        maxZoom={4}
        defaultViewport={viewport}
      >
        <Background
          size={2}
          color="#ccc"
          variant={BackgroundVariant.Cross}
          gap={[16, 16]}
        />
      </ReactFlow>
    </div>
  );
};

export default DiagramFlow;
